import axios from "axios";

const authHeaders = {
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  };

var authOptions = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  },
};

const config = {
    headers: {
      ...authHeaders,
      "Content-Type": "multipart/form-data", // Set the content type for FormData
    },
  };
  

class BlogService {
  async editBlogCategory(Id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Blog/EditBlogCategory/${Id}`,
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to edit blog category: " + error.message);
    }
  }
  async editBlog(Id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Blog/GetBlog/${Id}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error("Failed to edit blog category: " + error.message);
    }
  }
  async blogLIVE(Id,status) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Blog/MakeItLive?id=${Id}&status=${status}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error("Failed to edit blog category: " + error.message);
    }
  }
  async getImage(Id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Blog/GetImage/${Id}`,
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to edit image: " + error.message);
    }
  }
  async updateBlogCategory(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.put(
        process.env.REACT_APP_API_URL + "Blog/UpdateBlogCategory",
        formData,
        authOptions
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error(
        "Failed to add or update blog category: " + error.message
      );
    }
  }

  async updateBlog(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.put(
        process.env.REACT_APP_API_URL + "Blog/UpdateBlog",
        formData,
        authOptions
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error(
        "Failed to add or update blog category: " + error.message
      );
    }
  }

  async getBlogCategoryList() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "Blog/GetBlogCategoryList",
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to load blog category list: " + error.message);
    }
  }

  async getBlogList() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "Blog/GetBlogList",
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to load blog list: " + error.message);
    }
  }

  async addBlogCategory(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Blog/AddBlogCategory",
        formData,
        authOptions
      );

      return response.data; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error(
        "Failed to add blog category: " + error.message
      );
    }
  }

  async addBlog(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Blog/AddBlog",
        formData,
        authOptions
      );

      return response.data; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error(
        "Failed to add blog: " + error.message
      );
    }
  }

  async uploadImage(formData) {
    try {
       
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Blog/UploadImage",
        formData,
        config
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error(
        "Failed to add blog: " + error.message
      );
    }
  }


}

export default new BlogService();
