import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// import jwt_decode from 'jwt-decode';

const DoLogin = (credentials) => {
//   //Check if the user is already logged in
//   const userToken = localStorage.getItem('userToken');

// if (userToken) {
//   // Check if token is expired
//   const decodedToken = jwt_decode(userToken);
//   const currentTime = Date.now() / 1000; // Time in seconds
//   if (decodedToken.exp < currentTime) {
//     // Token is expired, redirect to login page
//     window.location.href = '/login';
//   } else {
//     // If token is valid, redirect to dashboard
//     window.location.href = '/dashboard';
//   }
// } else {
//   // User is not logged in, redirect to login page
//   window.location.href = '/login';
// }


  return fetch(process.env.REACT_APP_API_URL + 'auth/GetLogin', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(credentials),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (data === -1) {
        toastr.error('Login failed!');
      } else {
        localStorage.setItem('userToken', data.token);
        toastr.success('Login successful');
        window.location.href = '/dashboard';
      }
    })
    .catch((error) => {
      console.error('Fetch error:', error);
      toastr.error('Something went wrong!');
    });
};

export default DoLogin;
