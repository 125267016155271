import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import moment from 'moment';
import blogService from "../services/blogService";

function BlogCategoryList() {
    const [blogCategories, setBlogCategories] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    // const location = useLocation();

    // const getQueryParam = (param) => {
    //     const urlParams = new URLSearchParams(location.search);
    //     return urlParams.get(param);
    // };

    useEffect(() => {
        loadBlogCategories();
    }, []); // Empty dependency array to ensure the effect runs only once on mount

    const loadBlogCategories = () => {
        blogService.getBlogCategoryList()
        .then(response => {
            if (response && response.length > 0) {
                setBlogCategories(response);
            } else {
                setBlogCategories([]);
            }
            setLoading(false); // Set loading to false after API call             
        })
            .catch(() => {
                setLoading(false); // Set loading to false on error
                toastr.error('Something Went Wrong !');
            });
    };
    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Blog Category List</h1>
                                </div>
                                <div className="col-sm-6">
                                    <a href={`/add-blog-category`} className="btn btn-success float-right">
                                        <i className="fas fa-plus" style={{ fontSize: '0.8rem' }}></i> Add Blog Category
                                    </a>
                                    <a href={`/blog-category-list`} style={{ marginRight: '10px'}} className="btn btn-warning float-right">
                                        Back
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Created Date</th>
                                                        <th>Category Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {blogCategories.map((category, index) => (
                                                        <tr key={index}>
                                                            <td>{moment(category.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                            <td>{category.categoryName}</td>
                                                            <td>
                                                            <a href={"/edit-blog-category?id=" + category.categoryId}>
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterComponent />
            </div>
        </div>
    );
}

export default BlogCategoryList;
