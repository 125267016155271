import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import moment from 'moment';
import expertService from "../../services/expertService";

function ExpertList() {
    const [blogCategories, setBlogCategories] = useState([]);
    const hasFetchedData = useRef(false);  
    const [loading, setLoading] = useState(true); // Track loading state
    // const location = useLocation();

    // const getQueryParam = (param) => {
    //     const urlParams = new URLSearchParams(location.search);
    //     return urlParams.get(param);
    // };

    useEffect(() => {
        if (!hasFetchedData.current) {
        loadExperts();
        hasFetchedData.current = true;
        }
    }, []); // Empty dependency array to ensure the effect runs only once on mount

    const loadExperts = () => {
        expertService.getAllExperts()
        .then(response => {
            // console.log(response.data);
            if (response && response.data.length > 0) {
                setBlogCategories(response.data);
            } else {
                setBlogCategories([]);
            }
            setLoading(false); // Set loading to false after API call             
        })
            .catch(() => {
                setLoading(false); // Set loading to false on error
                toastr.error('Something Went Wrong !');
            });
    };

    function DeleteExpert(event, elem) {
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete this expert?")) {
            expertService.makeExpertRemove(elem)
            .then(response => {
              // console.log(response);
              if (response.data.result === 'success') {
                toastr.error(response.data.message);
                loadExperts();
              } else {
                toastr.error(response.data.message);
              }
            })
            .catch((error) => {
              toastr.error('Something Went Wrong !');
            })
        }
      }

      function makeExpertLIVE(event,id){
        event.preventDefault();
        const isChecked = event.target.checked;       
        expertService.makeExpertPublish(id, isChecked).then(response => {
          // console.log(response);
          if (response.data.result === 'success') {
            toastr.success(response.data.message);
            loadExperts();
          } else {
            toastr.error(response.data.message);
            loadExperts();
          }
        })
        .catch((error) => {
          toastr.error(error);
        })
      }

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Expert List</h1>
                                </div>
                                <div className="col-sm-6">
                                    <a href={`/expert-first-step`} className="btn btn-success float-right">
                                        <i className="fas fa-plus" style={{ fontSize: '0.8rem' }}></i> Add Expert
                                    </a>
                                    <a href={`/expert-list`} style={{ marginRight: '10px'}} className="btn btn-warning float-right">
                                        Back
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Created Date</th>
                                                        <th>Expert Name</th>
                                                        <th>Email ID</th>
                                                        <th>Action</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {blogCategories.map((category, index) => (
                                                        <tr key={index}>
                                                            <td>{moment(category.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                            <td>{category.firstName} {category.lastName}</td>
                                                            <td>{category.email}</td>
                                                            <td>
                                                            <a href={"/expert-first-step?id=" + category.expertId}>
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                                <a
  style={{ marginLeft: '10px' }}
  href="javascript:void(0)"
  onClick={(event) => DeleteExpert(event, category.expertId)}
>
  <i className="fas fa-trash-alt"></i>
</a> 

                                                            </td>
                                                            <td style={{marginTop: '50px'}}>
                  
                  {/* <a href="javascript:;" className="ml-2" onClick={(event) => makeServiceLIVE(event, item.servId)}><i className="fas fa-check"></i></a> */}
               
         <label className="switch">
           <input type="checkbox" checked={category.isVerified}  onChange={(event) => makeExpertLIVE(event, category.expertId)}/>
           <span className="slider" />
         </label>
         
         
                             </td>
                             <td>
                                <a class="btn btn-sm btn-dark" href={'/add-update-review?id='+category.expertId}>Add Review</a>
                             </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterComponent />
            </div>
        </div>
    );
}

export default ExpertList;
