import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr';
import { Editor } from '@tinymce/tinymce-react';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import blogService from "../services/blogService";

const schema = yup.object({
    CategoryName: yup.string().required(),
    CategoryDescription: yup.string(),
    VirtualUrl: yup.string().required(),
    MetaTitle: yup.string(),
    MetaDescription: yup.string().required(),
    MetaKeyword: yup.string(),
}).required();

function EditBlogCategory() {
    const location = useLocation();
    const navigate = useNavigate();
    const id = new URLSearchParams(location.search).get('id');

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const [categoryDescription, setCategoryDescription] = useState('');

    const fetchData = async () => {
        try {
            const response = await blogService.editBlogCategory(id);
            const data = response;

            setValue("categoryId", data.categoryId); // Set categoryId value
            setValue("CategoryName", data.categoryName);
            setValue("CategoryDescription", data.categoryDescription); // Set initial value for description
            setValue("VirtualUrl", data.virtualUrl);
            setValue("MetaTitle", data.metaTitle);
            setValue("MetaDescription", data.metaDescription);
            setValue("MetaKeyword", data.metaKeyword);

            setCategoryDescription(data.categoryDescription); // Set the editor's initial value
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onSubmit = async (data) => {
        try {
            data.CategoryDescription = categoryDescription; // Ensure the description is included in the submission
            const response = await blogService.updateBlogCategory(data);

            if (response.status === 200) {
                toastr.success('Blog category updated successfully');
                navigate('/blog-category-list'); // Navigate to blog category list
            } else {
                toastr.error('Failed to update blog category');
            }
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Edit Blog Category</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Edit Blog Category</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <input type="hidden" name="categoryId" {...register("categoryId")} /> {/* Hidden input for categoryId */}
                                                    <label htmlFor="CategoryName">Category Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="CategoryName"
                                                        {...register("CategoryName")}
                                                    />
                                                    {errors.CategoryName && <p className="text-danger">{errors.CategoryName.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="CategoryDescription">Category Description</label>
                                                    <Editor
                                                        className="form-control"
                                                        id="CategoryDescription"
                                                        apiKey='m0arsxlge8ikat6f412tdrr3p6vulctjb0m4rzivel855e77'
                                                        init={{
                                                            plugins: 'lists link image paste help wordcount',
                                                            toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code'
                                                        }}
                                                        value={categoryDescription}
                                                        onEditorChange={(content) => setCategoryDescription(content)}
                                                    />
                                                    {errors.CategoryDescription && <p className="text-danger">{errors.CategoryDescription.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="VirtualUrl">Virtual URL</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="VirtualUrl"
                                                        {...register("VirtualUrl")}
                                                    />
                                                    {errors.VirtualUrl && <p className="text-danger">{errors.VirtualUrl.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaTitle">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaTitle"
                                                        {...register("MetaTitle")}
                                                    />
                                                    {errors.MetaTitle && <p className="text-danger">{errors.MetaTitle.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaDescription">Meta Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="MetaDescription"
                                                        rows={3}
                                                        {...register("MetaDescription")}
                                                    />
                                                    {errors.MetaDescription && <p className="text-danger">{errors.MetaDescription.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaKeyword">Meta Keywords</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaKeyword"
                                                        {...register("MetaKeyword")}
                                                    />
                                                    {errors.MetaKeyword && <p className="text-danger">{errors.MetaKeyword.message}</p>}
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Update
                                                </button>
                                                <a href="/blog-category-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default EditBlogCategory;
