import axios from "axios";

var authOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  },
};

class FAQService {
  FAQList(Id) {
    return axios.get(
      process.env.REACT_APP_API_URL +
        "Service/GetFAQListByLink?Id=" +
        Id,
      authOptions
    );
  }
  DeleteFAQ(id) {
    return axios.get(
      process.env.REACT_APP_API_URL + "Service/FAQRemove?Id=" + id,
      authOptions
    );
  }
  FAQData(Id) {
    return axios.get(
      process.env.REACT_APP_API_URL +
        "Service/GetFAQList?Id=" +
        Id,
      authOptions
    );
  }
//   async GetFAQ(id, url) {
//     const apiUrl = process.env.REACT_APP_API_URL + "Service/GetServiceData";
//     const params = {};

//     if (id !== null && id !== undefined) {
//       params.Id = id;
//     }
//     if (url !== null && url !== undefined) {
//       params.url = url;
//     }

//     return await axios.get(apiUrl, {
//       params: params,
//       ...authOptions,
//     });
//   }

  async addUpdateFAQ(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Service/FaqAddorUpdate",
        formData,
        authOptions
      );

      return response.data; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error("Failed to add or update faq: " + error.message);
    }
  }
}

export default new FAQService();
