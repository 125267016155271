import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import otherService from "../services/otherService";
import Select from 'react-select';

const schema = yup.object({
    ServiceId: yup.number().required(),
    KeyWord: yup.string().required(),
    MainServId: yup.number()
}).required();

function AddFooterLinks() {
    const navigate = useNavigate();
    const { id } = useParams(); // Extracts the 'id' parameter from the URL
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });
    const query = window.location.search;
    const params = new URLSearchParams(query);

    const [serviceOptions, setServiceOptions] = useState([]);
    const [footerLinks, setFooterLinks] = useState([]);
    const [filteredFooterLinks, setFilteredFooterLinks] = useState([]);
    const hasFetchedData = useRef(false); // useRef to track initial data fetch

    useEffect(() => {
        
        if (!hasFetchedData.current) {
        fetchInitialData();
        hasFetchedData.current = true; // Update ref to true after initial fetch
    }
    }, [params]); // Only fetch data when params change

    useEffect(() => {
        if (Array.isArray(footerLinks)) {
            setFilteredFooterLinks(footerLinks);
        }
    }, [footerLinks]);

    async function fetchInitialData() {
        try {
           
                const servicesResponse = await otherService.getServices();
                const options = servicesResponse.data.map(service => ({
                    value: service.servId,
                    label: service.serviceTitle
                }));
                setServiceOptions(options);

                fetchFooterLinks();

              
        } catch (error) {
          //  toastr.error('Error fetching initial data');
            console.error('Error fetching initial data:', error);
        }
    }

    const onSubmit = async (data) => {
        try {
            data.MainServId = params.get('Id');
            const response = await otherService.addUpdateAddUpdFootLink(data);
            if (response.data.result === 1) {
                toastr.success(response.data.message);
                fetchFooterLinks(); // Fetch updated footer links after submission
            } else {
                toastr.error(response.data.message);
            }
        } catch (error) {
            toastr.error('Something went wrong!');
            console.error('Error submitting form:', error);
        }
    };

    const fetchFooterLinks = async () => {
        try {
            const response = await otherService.FootLinkList(params.get('Id'));
            setFooterLinks(response.data);
        } catch (error) {
            //toastr.error('Error fetching footer links');
            console.error('Error fetching footer links:', error);
        }
    };

    const handleDelete = async (linkId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this record?');
        if (confirmDelete) {
            try {
                const response = await otherService.deleteFootLink(linkId);
                if (response.status === 200) {
                    toastr.success(response.data.message);
                    fetchFooterLinks(); // Fetch updated footer links after deletion
                } else {
                    toastr.error(response.data.message);
                }
            } catch (error) {
                toastr.error('Something went wrong!');
                console.error('Error deleting link:', error);
            }
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Add Inter Links</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add Inter Links</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="ServiceId">Choose Service</label>
                                                    <Select
    options={serviceOptions}
    onChange={(selectedOption) => {
        setValue('ServiceId', selectedOption.value);
      
    }}
/>

                                                    {errors.ServiceId && <p className="text-danger">{errors.ServiceId.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="KeyWord">Keywords</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="KeyWord"
                                                        placeholder="Enter Keyword"
                                                        {...register("KeyWord")}
                                                    />
                                                    {errors.KeyWord && <p className="text-danger">{errors.KeyWord.message}</p>}
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                                <a href="/service-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <h5 className="card-title">Footer Links</h5> */}
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Keyword</th>
                                                        <th>Service Title</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredFooterLinks.map(link => (
                                                        <tr key={link.id}>
                                                            <td>{link.keyWord}</td>
                                                            <td>{link.serviceTitle}</td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={() => handleDelete(link.id)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default AddFooterLinks;
