import axios from "axios";

var authOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  },
};

class ProdService {
  ServiceList(pageNum, pageSize) {
    return axios.get(
      process.env.REACT_APP_API_URL +
        "Service/ServiceList?pageNum=" +
        pageNum +
        "&pageSize=" +
        pageSize,
      authOptions
    );
  }
  DeleteService(id) {
    return axios.get(
      process.env.REACT_APP_API_URL + "Service/DeleteService?id=" + id,
      authOptions
    );
  }
  async serviceLIVE(Id,status) {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "Service/MakeItLive?id=" + Id +"&status="+status,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error("Failed to edit service: " + error.message);
    }
  }
  async GetService(id, url) {
    const apiUrl = process.env.REACT_APP_API_URL + "Service/GetServiceData";
    const params = {};

    if (id !== null && id !== undefined) {
      params.Id = id;
    }
    if (url !== null && url !== undefined) {
      params.url = url;
    }

    return await axios.get(apiUrl, {
      params: params,
      ...authOptions,
    });
  }

  async addUpdateService(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Service/AddOrUpdateService",
        formData,
        authOptions
      );

      return response.data; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error("Failed to add or update blog: " + error.message);
    }
  }
}

export default new ProdService();
