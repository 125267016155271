import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import moment from 'moment';
import expertService from "../../services/expertService";

function ExpertCategoryList() {
    const [expertCategories, setExpertCat] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    // const location = useLocation();

    // const getQueryParam = (param) => {
    //     const urlParams = new URLSearchParams(location.search);
    //     return urlParams.get(param);
    // };

    useEffect(() => {
        loadExpertCategories();
    }, []); // Empty dependency array to ensure the effect runs only once on mount


    async function DeleteExpertCat(event, elem) {
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete this expert category?")) {
            try {
                const response = await expertService.deleteExpertCategory(elem);
    
                if (response.status === 200) {
                    toastr.success('Expert Category Deleted Successfully');
                    loadExpertCategories(); // Refresh the list of expert categories
                } else {
                    toastr.error('Something Went Wrong!');
                }
            } catch (error) {
                toastr.error('Failed to delete expert help category: ' + error.message);
            }
        }
    }
    
    

    const loadExpertCategories = () => {
        expertService.getExpertCategoryList()
        .then(response => {
            if (response && response.length > 0) {
                setExpertCat(response);
            } else {
                setExpertCat([]);
            }
            setLoading(false); // Set loading to false after API call             
        })
            .catch(() => {
                setLoading(false); // Set loading to false on error
                toastr.error('Something Went Wrong !');
            });
    };
    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Expert Help Category List</h1>
                                </div>
                                <div className="col-sm-6">
                                    <a href={`/expert-add-category`} className="btn btn-warning float-right">
                                        <i className="fas fa-plus" style={{ fontSize: '0.8rem' }}></i> Add Expert Help Category 
                                    </a>
                                    {/* <a href={`/expert-category-list`} style={{ marginRight: '10px'}} className="btn btn-warning float-right">
                                        Back
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Created Date</th>
                                                        <th>Expert Category Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {expertCategories.map((category, index) => (
                                                        <tr key={index}>
                                                            <td>{moment(category.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                            <td>{category.helpCatName}</td>
                                                            <td>
                                                            <a href={"/expert-edit-category?id=" + category.helpCId}>
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                                <a
  style={{ marginLeft: '10px' }}
  href="javascript:void(0)"
  onClick={(event) => DeleteExpertCat(event, category.helpCId)}
>
  <i className="fas fa-trash-alt"></i>
</a> 
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterComponent />
            </div>
        </div>
    );
}

export default ExpertCategoryList;
