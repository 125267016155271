import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import expertService from "../../services/expertService";

const schema = yup.object({
    HelpCId: yup.number().required(),
    HelpCatName: yup.string().required()
}).required();

function AddExpertFourthStepComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const [expertCategories, setExpertCat] = useState([]);
    const [loading, setLoading] = useState(true);
    const [checkedHelpCat, setCheckedHelpCat] = useState([]);

    useEffect(() => {
        loadExpertCategories();
        HelpCatListData();
    }, []);

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    const id = getQueryParam('id') === null ? 0 : parseInt(getQueryParam('id'));

    const loadExpertCategories = () => {
        expertService.getExpertCategoryList()
            .then(response => {
                if (response && response.length > 0) {
                    setExpertCat(response);
                } else {
                    setExpertCat([]);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                toastr.error('Something Went Wrong !');
            });
    };

    const HelpCatListData = async () => {
        try {
            const response = await expertService.getExpertHelpCatListData(id);
            const checkedHelpCat = response.map(help => help.helpCId);
            setCheckedHelpCat(checkedHelpCat);
        } catch (error) {
            toastr.error('Failed to fetch expert help categories: ' + error.message);
        }
    };

    const handleHelpCatChange = async (e, categoryId) => {
        const isChecked = e.target.checked;

        // Create a new array to hold updated checkedHelpCat
        let updatedCheckedHelpCat = [...checkedHelpCat];

        if (isChecked) {
            // Add categoryId to updatedCheckedHelpCat if checked
            updatedCheckedHelpCat.push(categoryId);
        } else {
            // Remove categoryId from updatedCheckedHelpCat if unchecked
            updatedCheckedHelpCat = updatedCheckedHelpCat.filter(catId => catId !== categoryId);
        }

        // Update checkedHelpCat state
        setCheckedHelpCat(updatedCheckedHelpCat);

        // Perform API call to add or remove help category
        const formData = new FormData();
        formData.append('ExpertId', id);
        formData.append('HelpCId', categoryId);

        try {
            const response = await expertService.addorremoveExpertCategory(formData);
            if (response.data.result === 'success') {
                toastr.success(response.data.message);
            } else {
                toastr.error(response.data.message);
                // Revert UI state if API call fails
                setCheckedHelpCat(prevCats => prevCats.filter(catId => catId !== categoryId));
            }
        } catch (error) {
            toastr.error('Failed to update help category: ' + error.message);
            // Revert UI state if API call fails
            setCheckedHelpCat(prevCats => prevCats.filter(catId => catId !== categoryId));
        }
    };

    const PrevPage = (mode) => {
        navigate(`/${mode}?id=${id}`);
    };


    const onPublish = async () => {
        try {
            const response = await expertService.makeExpertPublish(id,true);

            if (response.status === 200) {
                if(response.data.result=='success')
                {
                    toastr.success(response.data.message);
                    navigate(`/expert-list`);
                }
                else{
                    toastr.error(response.data.message);
                }               
            } 
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Add/Update Expert</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add/Update Expert</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-default">
                                        <div className="card-body p-0">
                                            <div className="bs-stepper">
                                                <div className="bs-stepper-header" role="tablist">
                                                    <div className="step active" data-target="#logins-part">
                                                        <button type="button" onClick={() => PrevPage('expert-first-step')} className="step-trigger" role="tab" aria-controls="logins-part" id="logins-part-trigger">
                                                            <span className="bs-stepper-circle">1</span>
                                                            <span className="bs-stepper-label">Basic Info</span>
                                                        </button>
                                                    </div>
                                                    <div className="line" />
                                                    <div className="step active" data-target="#information-part">
                                                        <button type="button" onClick={() => PrevPage('expert-second-step')} className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                            <span className="bs-stepper-circle">2</span>
                                                            <span className="bs-stepper-label">Upload Photo</span>
                                                        </button>
                                                    </div>
                                                    <div className="line" />
                                                    <div className="step active" data-target="#information-part">
                                                        <button type="button" onClick={() => PrevPage('expert-third-step')} className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                            <span className="bs-stepper-circle">3</span>
                                                            <span className="bs-stepper-label">Skill</span>
                                                        </button>
                                                    </div>
                                                    <div className="line" />
                                                    <div className="step active" data-target="#information-part">
                                                        <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                            <span className="bs-stepper-circle">4</span>
                                                            <span className="bs-stepper-label">Category</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="bs-stepper-content d-block">
                                                    <div id="logins-part" className="content active" role="tabpanel" aria-labelledby="logins-part-trigger">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row py-4" id="skill">
                                                                    {loading ? (
                                                                        <p>Loading...</p>
                                                                    ) : (
                                                                        expertCategories.map(category => (
                                                                            <div key={category.helpCId} className="col-md-2">
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        id={`category-${category.helpCId}`}
                                                                                        value={category.helpCId}
                                                                                        checked={checkedHelpCat.includes(category.helpCId)}
                                                                                        onChange={(e) => handleHelpCatChange(e, category.helpCId)}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor={`category-${category.helpCId}`}>
                                                                                        {category.helpCatName}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <button className="btn btn-primary" onClick={onPublish}>Make It LIVE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default AddExpertFourthStepComponent;
