import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Auth/login.component";
import AddService from "./ProductService/add-services.component";
import EditService from "./ProductService/edit-service.component";
import ServiceList from "./ProductService/service-list.component";
import Dashboard from "./Dashboard/career-dashboard.component";
import ProtectedRoute from "./provider/ProtectedRoute";
import FAQList from "./ProductService/faq-list.component";
import AddEditFAQ from "./ProductService/add-faq.component";
import EditFAQ from "./ProductService/edit-faq.component";
import AddBlogCategory from "./Blog/add-blog-category.component";
import EditBlogCategory from "./Blog/edit-blog-category.component";
import BlogCategoryList from "./Blog/blog-category-list.component";
import AddBlog from "./Blog/add-blog.component";
import BlogList from "./Blog/blog-list.component";
import EditBlog from "./Blog/edit-blog.component";
import AddHelpCategory from "./Experts/HelpCategory/add-help-category.component";
import ExpertCategoryList from "./Experts/HelpCategory/help-category-list.component";
import EditHelpCategory from "./Experts/HelpCategory/edit-help-category.component";
import AddSkillCategory from "./Experts/SkillCategory/add-skill-category.component";
import EditSkillCategory from "./Experts/SkillCategory/edit-skill-category.component";
import ExpertSkillCategoryList from "./Experts/SkillCategory/skill-category-list.component";
import AddFooterLinks from "./ProductService/add-footer-links.component";
import AddExpertFirstStepComponent from "./Experts/AddExpert/add-step-first.component";
import AddExpertSecondStepComponent from "./Experts/AddExpert/add-step-second.component";
import AddExpertThirdStepComponent from "./Experts/AddExpert/add-step-third.component";
import AddExpertFourthStepComponent from "./Experts/AddExpert/add-step-fourth.component";
import ExpertList from "./Experts/AddExpert/expert-list.component";
import AddUpdateReview from "./Experts/AddExpert/add-update-reviews";
import ExpertReviewList from "./Experts/AddExpert/reviews-list";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='/add-service' element={<ProtectedRoute component={AddService} />} />
        <Route path='/dashboard' element={<ProtectedRoute component={Dashboard} />} />
        <Route path='/edit-service' element={<ProtectedRoute component={EditService} />} />
        <Route path='/service-list' element={<ProtectedRoute component={ServiceList} />} />
        <Route path='/faq-list' element={<ProtectedRoute component={FAQList} />} />
        <Route path='/add-faq' element={<ProtectedRoute component={AddEditFAQ} />} />
        <Route path='/edit-faq' element={<ProtectedRoute component={EditFAQ} />} />
        <Route path='/add-blog-category' element={<ProtectedRoute component={AddBlogCategory} />} />
        <Route path='/edit-blog-category' element={<ProtectedRoute component={EditBlogCategory} />} />
        <Route path='/blog-category-list' element={<ProtectedRoute component={BlogCategoryList} />} />
        <Route path='/add-blog' element={<ProtectedRoute component={AddBlog} />} />
        <Route path='/blog-list' element={<ProtectedRoute component={BlogList} />} />
        <Route path='/edit-blog' element={<ProtectedRoute component={EditBlog} />} />
        <Route path='/expert-add-category' element={<ProtectedRoute component={AddHelpCategory} />} />
        <Route path='/expert-category-list' element={<ProtectedRoute component={ExpertCategoryList} />} />
        <Route path='/expert-edit-category' element={<ProtectedRoute component={EditHelpCategory} />} />
        <Route path='/expert-skill-add-category' element={<ProtectedRoute component={AddSkillCategory} />} />
        <Route path='/expert-skill-category-list' element={<ProtectedRoute component={ExpertSkillCategoryList} />} />
        <Route path='/expert-skill-edit-category' element={<ProtectedRoute component={EditSkillCategory} />} />
        <Route path='/expert-first-step' element={<ProtectedRoute component={AddExpertFirstStepComponent} />} />
        <Route path='/expert-second-step' element={<ProtectedRoute component={AddExpertSecondStepComponent} />} />
        <Route path='/expert-third-step' element={<ProtectedRoute component={AddExpertThirdStepComponent} />} />
        <Route path='/expert-fourth-step' element={<ProtectedRoute component={AddExpertFourthStepComponent} />} />
        <Route path='/add-footer-link' element={<ProtectedRoute component={AddFooterLinks} />} />        
        <Route path='/expert-list' element={<ProtectedRoute component={ExpertList} />} />       
        <Route path='/add-update-review' element={<ProtectedRoute component={AddUpdateReview} />} />  
        <Route path='/expert-reviews' element={<ProtectedRoute component={ExpertReviewList} />} />  
      </Routes>
    </div>
  );
};

export default App;
