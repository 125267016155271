import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'highlight.js/styles/monokai.css'; // Correct CSS import
import blog from "../services/prodservice";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';

// Register languages with highlight.js
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', xml);

const schema = yup.object({
    servId: yup.number(),
    bannerTitle: yup.string().required(),
    serviceTitle: yup.string().required(),
    virtualUrl: yup.string().required(),
    metaTitle: yup.string().required(),
    metaDescription: yup.string().required(),
    metaKeywords: yup.string()
}).required();

const modules = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['code-block'], // Added code-block option
        ['clean']
    ],
    syntax: {
        highlight: text => hljs.highlightAuto(text).value,
    },
};

const formats = [
    'font', 'header', 'size', 'bold', 'italic', 'underline', 'strike',
    'color', 'background', 'script', 'list', 'bullet', 'indent',
    'direction', 'align', 'link', 'image', 'video', 'code-block'
];

function EditService() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });
    const [blogData, setBlogData] = useState({
        serviceContent: '',
        bannerContent: ''
    });
    const [isLoaded, setIsLoaded] = useState(false);

    const query = window.location.search;
    const params = new URLSearchParams(query);

    async function fetchData() {
        try {
            const response = await blog.GetService(params.get('id'), null);
            setBlogData(response.data[0]);
            setIsLoaded(true);
        } catch (error) {
            console.error('Error fetching service data:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const selectedCategory = watch("serviceTitle");

    useEffect(() => {
        if (selectedCategory) {
            const virtualUrl = selectedCategory
                .replace(/[^a-z0-9\s]/gi, '')
                .replace(/^\s+/g, '')
                .replace(/\s+$/g, '')
                .replace(/[_\s]/g, '-')
                .toLowerCase()
                .substring(0, 140);
            setValue("virtualUrl", virtualUrl);
        }
    }, [selectedCategory, setValue]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBlogData({ ...blogData, [name]: value });
    };

    const handleEditorChange = (content) => {
        setBlogData({ ...blogData, serviceContent: content });
    };

    const handleEditorChange2 = (content) => {
        setBlogData({ ...blogData, bannerContent: content });
    };

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append("ServId", parseInt(params.get('id') || 0));
            formData.append("BannerTitle", data.bannerTitle);
            formData.append("BannerContent", blogData.bannerContent);
            formData.append("ServiceTitle", data.serviceTitle);
            formData.append("ServiceContent", blogData.serviceContent);
            formData.append("MetaTitle", data.metaTitle);
            formData.append("MetaKeywords", data.metaKeywords);
            formData.append("MetaDescription", data.metaDescription);
            formData.append("VirtualUrl", data.virtualUrl);

            const response = await blog.addUpdateService(formData);

            if (response === -1) {
                toastr.error('Service already exists !!');
            } else {
                toastr.success('Service updated successfully');
                navigate('/service-list');
            }
        } catch (error) {
            toastr.error('Something Went Wrong !');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Edit Service</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Edit Service</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Service</h3>
                                        </div>
                                        {isLoaded &&
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <input type="hidden" id="servId" name="servId" value={params.get('id') || 0} {...register("servId")} />
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="bannerTitle">Banner Title</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="bannerTitle"
                                                            placeholder="Enter Banner Title"
                                                            name="bannerTitle"
                                                            {...register("bannerTitle")}
                                                            value={blogData.bannerTitle || ''} onChange={handleChange}
                                                        />
                                                        {errors.bannerTitle && <p className="invalid-feedback">{errors.bannerTitle.message}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="bannerContent">Banner Content</label>
                                                        <ReactQuill
                                                            value={blogData.bannerContent || ''}
                                                            onChange={handleEditorChange2}
                                                            modules={modules}
                                                            formats={formats}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="serviceTitle">Service Title</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="serviceTitle"
                                                            placeholder="Enter Service Title"
                                                            name="serviceTitle"
                                                            {...register("serviceTitle")}
                                                            value={blogData.serviceTitle || ''} onChange={handleChange}
                                                        />
                                                        {errors.serviceTitle && <p className="invalid-feedback">{errors.serviceTitle.message}</p>}
                                                    </div>
                                                    <div className="form-group" style={{ height: '300px' }}>
                                                        <label htmlFor="serviceContent">Service Content</label>
                                                        <ReactQuill
                                                            value={blogData.serviceContent || ''}
                                                            onChange={handleEditorChange}
                                                            modules={modules}
                                                            formats={formats}
                                                             className="important-height"
                                                        />
                                                    </div>
                                                    <div className="form-group mt-5 pt-5">
                                                        <label htmlFor="metaTitle">Meta Title</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="metaTitle"
                                                            placeholder="Enter Meta Title"
                                                            name="metaTitle"
                                                            {...register("metaTitle")}
                                                            value={blogData.metaTitle || ''} onChange={handleChange}
                                                        />
                                                        {errors.metaTitle && <p className="invalid-feedback">{errors.metaTitle.message}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="metaDescription">Meta Description</label>
                                                        <textarea
                                                            className="form-control"
                                                            id="metaDescription"
                                                            placeholder="Enter Meta Description"
                                                            rows={5}
                                                            name="metaDescription"
                                                            {...register("metaDescription")}
                                                            value={blogData.metaDescription || ''} onChange={handleChange}
                                                        ></textarea>
                                                        {errors.metaDescription && <p className="invalid-feedback">{errors.metaDescription.message}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="metaKeywords">Meta Keywords</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="metaKeywords"
                                                            placeholder="Enter Meta Keywords"
                                                            name="metaKeywords"
                                                            {...register("metaKeywords")}
                                                            value={blogData.metaKeywords || ''} onChange={handleChange}
                                                        />
                                                        {errors.metaKeywords && <p className="invalid-feedback">{errors.metaKeywords.message}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                    <label htmlFor="virtualUrl">Virtual Url</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="virtualUrl"
                                                        placeholder="Enter Virtual Url"
                                                        name="virtualUrl"
                                                        value={blogData.virtualUrl || ''}
                                                        {...register("virtualUrl")} onChange={handleChange}
                                                        
                                                    />
                                                    {errors.virtualUrl && <p className="invalid-feedback">{errors.virtualUrl.message}</p>}
                                                </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterComponent />
            </div>
        </div>
    );
}

export default EditService;
