import React, { useState, useEffect } from "react";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import { useNavigate } from "react-router-dom";
import blog from "../services/prodservice";
import toastr from 'toastr';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as yup from "yup";
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';

// Register languages with highlight.js
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', xml);

const schema = yup.object({
    BannerTitle: yup.string().required(),
    ServiceTitle: yup.string().required(),
    VirtualUrl: yup.string().required(),
    MetaTitle: yup.string().required(),
    MetaDescription: yup.string().required(),
    MetaKeywords: yup.string()
}).required();

const modules = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['code-block'], // Added code-block option
        ['clean']
    ],
    syntax: {
        highlight: text => hljs.highlightAuto(text).value,
    },
};

const formats = [
    'font', 'header', 'size', 'bold', 'italic', 'underline', 'strike',
    'color', 'background', 'script', 'list', 'bullet', 'indent',
    'direction', 'align', 'link', 'image', 'video', 'code-block'
];

function AddService() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });

    const [bannerContent, setBannerContent] = useState('');
    const [serviceContent, setServiceContent] = useState('');

    const handleBannerContentChange = (content) => {
        setBannerContent(content);
    };

    const handleServiceContentChange = (content) => {
        setServiceContent(content);
    };

    const selectedCategory = watch("ServiceTitle");

    useEffect(() => {
        if (selectedCategory) {
            const virtualUrl = selectedCategory
                .replace(/[^a-z0-9\s]/gi, '')
                .replace(/^\s+/g, '')
                .replace(/\s+$/g, '')
                .replace(/[_\s]/g, '-')
                .toLowerCase()
                .substring(0, 140);
            setValue("VirtualUrl", virtualUrl);
        }
    }, [selectedCategory, setValue]);

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append("BannerTitle", data.BannerTitle);
            formData.append("BannerContent", bannerContent);
            formData.append("ServiceTitle", data.ServiceTitle);
            formData.append("ServiceContent", serviceContent);
            formData.append("MetaTitle", data.MetaTitle);
            formData.append("MetaDescription", data.MetaDescription);
            formData.append("MetaKeywords", data.MetaKeywords);
            formData.append("VirtualUrl", data.VirtualUrl);

            const response = await blog.addUpdateService(formData);

            if (response === -1) {
                toastr.error('Service already exists !!');
            } else {
                toastr.success('Service added successfully');
                navigate('/service-list');
            }
        } catch (error) {
            toastr.error('Something Went Wrong !');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Add Service</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add Service</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Service</h3>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="BannerTitle">Banner Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="BannerTitle"
                                                        placeholder="Enter Banner Title"
                                                        name="BannerTitle"
                                                        {...register("BannerTitle")}
                                                    />
                                                    {errors.BannerTitle && <p className="invalid-feedback">{errors.BannerTitle.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="BannerContent">Banner Content</label>
                                                    <ReactQuill
                                                        value={bannerContent}
                                                        onChange={handleBannerContentChange}
                                                        modules={modules}
                                                        formats={formats}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="ServiceTitle">Service Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="ServiceTitle"
                                                        placeholder="Enter Service Title"
                                                        name="ServiceTitle"
                                                        {...register("ServiceTitle")}
                                                    />
                                                    {errors.ServiceTitle && <p className="invalid-feedback">{errors.ServiceTitle.message}</p>}
                                                </div>
                                                <div className="form-group" style={{ height: '300px' }}>
                                                    <label htmlFor="ServiceContent">Service Content</label>
                                                    <ReactQuill
                                                        value={serviceContent}
                                                        onChange={handleServiceContentChange}
                                                        modules={modules}
                                                        formats={formats}
                                                         className="important-height"
                                                    />
                                                </div>
                                                <div className="form-group mt-5 pt-5">
                                                    <label htmlFor="MetaTitle">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaTitle"
                                                        placeholder="Enter Meta Title"
                                                        name="MetaTitle"
                                                        {...register("MetaTitle")}
                                                    />
                                                    {errors.MetaTitle && <p className="invalid-feedback">{errors.MetaTitle.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaKeywords">Meta Keywords</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaKeywords"
                                                        placeholder="Enter Meta Keywords"
                                                        name="MetaKeywords"
                                                        {...register("MetaKeywords")}
                                                    />
                                                    {errors.MetaKeywords && <p className="invalid-feedback">{errors.MetaKeywords.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaDescription">Meta Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="MetaDescription"
                                                        placeholder="Enter Meta Description"
                                                        rows={5}
                                                        name="MetaDescription"
                                                        {...register("MetaDescription")}
                                                    ></textarea>
                                                    {errors.MetaDescription && <p className="invalid-feedback">{errors.MetaDescription.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="VirtualUrl">Virtual Url</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="VirtualUrl"
                                                        placeholder="Enter Virtual Url"
                                                        name="VirtualUrl"
                                                        value={watch("VirtualUrl")}
                                                        {...register("VirtualUrl")}
                                                    />
                                                    {errors.VirtualUrl && <p className="invalid-feedback">{errors.VirtualUrl.message}</p>}
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Save As Draft
                                                </button>
                                                <a href="/service-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default AddService;
