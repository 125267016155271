import React, {useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr';
import { Editor } from '@tinymce/tinymce-react';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import blogService from "../services/blogService";

const schema = yup.object({
    CategoryName: yup.string().required(),
    // CategoryDescription: yup.string(),
    VirtualUrl: yup.string().required(),
    MetaTitle: yup.string(),
    MetaDescription: yup.string().required(),
    MetaKeyword: yup.string(),
}).required();

function AddBlogCategory() {
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });

    const [categoryDescription, setCategoryDescription] = useState('');
    
    const HandleEditorChange = (content) =>{
        setCategoryDescription(content);
    }
    
    const selectedCategory = watch("CategoryName");

    useEffect(() => {
        if (selectedCategory) {
            const virtualUrl = selectedCategory
                .replace(/[^a-z0-9\s]/gi, '')
                .replace(/^\s+/g, '')
                .replace(/\s+$/g, '')
                .replace(/[_\s]/g, '-')
                .toLowerCase()
                .substring(0, 140);
            setValue("VirtualUrl", virtualUrl);
        }
    }, [selectedCategory, setValue]);

    const onSubmit = async (data) => {
        try {
            data.CategoryDescription=categoryDescription;
            const response = await blogService.addBlogCategory(data);

            if (response.status === 409) {
                toastr.error(response.data.message);
            } else {
                toastr.success('Blog category added successfully');
                navigate('/blog-category-list'); // Navigate to blog category list
            }
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Add Blog Category</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add Blog Category</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="CategoryName">Category Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="CategoryName"
                                                        placeholder="Enter Category Name"
                                                        {...register("CategoryName")}
                                                    />
                                                    {errors.CategoryName && <p className="text-danger">{errors.CategoryName.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="CategoryDescription">Category Description</label>
                                                    <Editor
                                                        className="form-control"
                                                        id="CategoryDescription"
                                                        apiKey='m0arsxlge8ikat6f412tdrr3p6vulctjb0m4rzivel855e77'
                                                        placeholder="Enter Category Description"
                                                        init={{
                                                            plugins: 'lists link image paste help wordcount',
                                                            toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code'
                                                        }}
                                                        onEditorChange={HandleEditorChange}
                                                    />
                                                    {/* {errors.CategoryDescription && <p className="text-danger">{errors.CategoryDescription.message}</p>} */}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="VirtualUrl">Virtual URL</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="VirtualUrl"
                                                        placeholder="Enter Virtual URL"
                                                        {...register("VirtualUrl")}
                                                    />
                                                    {errors.VirtualUrl && <p className="text-danger">{errors.VirtualUrl.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaTitle">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaTitle"
                                                        placeholder="Enter Meta Title"
                                                        {...register("MetaTitle")}
                                                    />
                                                    {errors.MetaTitle && <p className="text-danger">{errors.MetaTitle.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaDescription">Meta Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="MetaDescription"                                                      
                                                        placeholder="Enter Meta Description"
                                                        rows={3}
                                                        {...register("MetaDescription")}
                                                   ></textarea>
                                                    {errors.MetaDescription && <p className="text-danger">{errors.MetaDescription.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaKeyword">Meta Keywords</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaKeyword"
                                                        placeholder="Enter Meta Keywords"
                                                        {...register("MetaKeyword")}
                                                    />
                                                    {errors.MetaKeyword && <p className="text-danger">{errors.MetaKeyword.message}</p>}
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                                <a href="/blog-category-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default AddBlogCategory;
