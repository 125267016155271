import React, { useState  } from "react";

function MenuComponent() {
  const [activeLink, setActiveLink] = useState(null);

  const handleClick = (index) => {
      setActiveLink(index === activeLink ? null : index);
  };

    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <a href="index3.html" className="brand-link">
          <img
            src="https://www.mycoursehelp.com/assets/img/logo2.png"
            alt="AdminLTE Logo"
            className="w-100"
            style={{ opacity: ".8" }}
          />
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
         
          {/* SidebarSearch Form */}
          
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library */}
              <li className="nav-item">
                <a href="/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Dashboard
                
                  </p>
                </a>
               
              </li>
              
              <li className="nav-item">
                <a href="#" className={`nav-link ${activeLink === 0 ? 'active' : ''}`} onClick={() => handleClick(0)}>
                  <i className="nav-icon fas fa-copy" />
                  <p>
                  Service
                    <i className="fas fa-angle-left right" />
                 
                  </p>
                </a>
                <ul className={`nav flex-column ${activeLink === 0 ? 'd-block' : 'd-none'}`}>
                  
                  <li className="nav-item">
                    <a href="/add-service" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Service</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/service-list"
                      className="nav-link"
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Service List</p>
                    </a>
                  </li>
               
                </ul>
              </li>
           
              <li className="nav-item">
                <a href="#" className={`nav-link ${activeLink === 1 ? 'active' : ''}`} onClick={() => handleClick(1)}>
                  <i className="nav-icon fas fa-file" />
                  <p>
                  Blog
                    <i className="fas fa-angle-left right" />
                 
                  </p>
                </a>
                <ul className={`nav flex-column ${activeLink === 1 ? 'd-block' : 'd-none'}`}>
                  
                  <li className="nav-item">
                    <a href="/add-blog-category" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Blog Category</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/blog-category-list"
                      className="nav-link"
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Blog Category List</p>
                    </a>
                  </li>
                 
                  <li className="nav-item">
                    <a href="/add-blog" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add Blog</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/blog-list"
                      className="nav-link"
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Blog List</p>
                    </a>
                  </li>
                </ul>
              </li>
             
              <li className="nav-item">
                <a href="#" className={`nav-link ${activeLink === 2 ? 'active' : ''}`} onClick={() => handleClick(2)}>
                  <i className="nav-icon fas fa-user" />
                  <p>
                  Expert
                    <i className="fas fa-angle-left right" />
                 
                  </p>
                </a>
                <ul className={`nav flex-column ${activeLink === 2 ? 'd-block' : 'd-none'}`}>
                  
                  <li className="nav-item">
                    <a href="/expert-category-list" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Manage Expert Category</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/expert-skill-category-list" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Manage Expert Skill</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/expert-first-step" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Add/Update Expert</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/expert-list" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Experts List</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/expert-reviews" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Expert Reviews</p>
                    </a>
                  </li>
                </ul>
              </li>
            
           
             
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
)
}

export default MenuComponent;