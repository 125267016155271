import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import moment from "moment";
import blogService from "../services/blogService";

function BlogList() {
  const [blogCategories, setBlogCategories] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  // const location = useLocation();

  // const getQueryParam = (param) => {
  //     const urlParams = new URLSearchParams(location.search);
  //     return urlParams.get(param);
  // };

  useEffect(() => {
    loadBlogCategories();
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const loadBlogCategories = () => {
    blogService
      .getBlogList()
      .then((response) => {
        if (response && response.length > 0) {
          setBlogCategories(response);
        } else {
          setBlogCategories([]);
        }
        setLoading(false); // Set loading to false after API call
        // if (Array.isArray(response.data)) {
        //     console.log(response.data);
        //     setBlogCategories(response.data);
        // } else {
        //     console.error('Expected array but received:', response.data);
        //     setBlogCategories([]);
        // }
      })
      .catch(() => {
        setLoading(false); // Set loading to false on error
        toastr.error("Something Went Wrong !");
      });
  };

  function makeBlogPageLIVE(event, id) {
    event.preventDefault();
    const isChecked = event.target.checked;
    blogService
      .blogLIVE(id,isChecked)
      .then((response) => {
        // console.log(response);
        toastr.success(response.data.message);
        loadBlogCategories();
      })
      .catch((error) => {
        toastr.error(error);
      });
  }
  return (
    <div className="hold-transition sidebar-mini">
      <div className="wrapper">
        <HeaderComponent />
        <MenuComponent />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Blog List</h1>
                </div>
                <div className="col-sm-6">
                  <a href={`/add-blog`} className="btn btn-success float-right">
                    <i
                      className="fas fa-plus"
                      style={{ fontSize: "0.8rem" }}
                    ></i>{" "}
                    Add Blog
                  </a>
                  <a
                    href={`/blog-list`}
                    style={{ marginRight: "10px" }}
                    className="btn btn-warning float-right"
                  >
                    Back
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>Created Date</th>
                            <th>Blog Name</th>
                            <th>Action</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {blogCategories.map((category, index) => (
                            <tr key={index}>
                              <td>
                                {moment(category.createdDate).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                              <td>{category.blogName}</td>
                              <td>
                                <a href={"/edit-blog?id=" + category.id}>
                                  <i className="far fa-edit"></i>
                                </a>

                                {/* {category.isActive === false && (
                                  <a
                                    href="javascript:;"
                                    className="ml-2"
                                    onClick={(event) =>
                                      makeBlogPageLIVE(event, category.id)
                                    }
                                  >
                                    <i className="fas fa-check"></i>
                                  </a>
                                )} */}
                              </td>
                              <td style={{ marginTop: "50px" }}>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={category.isActive}
                                    onChange={(event) =>
                                        makeBlogPageLIVE(event, category.id)
                                    }
                                  />
                                  <span className="slider" />
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FooterComponent />
      </div>
    </div>
  );
}

export default BlogList;
