import axios from "axios";

var authOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  },
};

class OtherService {

    async addUpdateAddUpdFootLink(formData) {
        try {
          // Make the API call with the formData
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "Other/SaveFootMainLink",
            formData,
            authOptions
          );
    
          return response; // Return the response data
        } catch (error) {
          // Handle errors
          throw new Error("Failed to add or update upper footer link: " + error.message);
        }
      }


    //   DeleteFootLink(id) {
    //     return axios.get(
    //       process.env.REACT_APP_API_URL + "Other/DeleteFootMainLink/" + id,
    //       authOptions
    //     );
    //   }

      async deleteFootLink(id) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}Other/DeleteFootMainLink?id=${id}`,
            authOptions
          );
          return response; // Return the response data
        } catch (error) {
          throw new Error("Failed to delete footer link: " + error.message);
        }
      }

      FootLinkList(Id) {
        return axios.get(
          process.env.REACT_APP_API_URL +
            "Web/GetFootMainLinks?id=" +
            Id,
          authOptions
        );
      }
      getServices(){
        return axios.get(
            process.env.REACT_APP_API_URL + "Web/GetServices",
            authOptions
          );
      }

}

export default new OtherService();