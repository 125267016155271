import React, {useEffect,useState} from "react";
import { useNavigate,useLocation } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import { Editor } from '@tinymce/tinymce-react';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import expertService from "../../services/expertService";

const schema = yup.object({
    ExpertId: yup.number(),
    imageFile: yup.string().required()
}).required();



function AddExpertSecondStepComponent(){

    
    const location = useLocation();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });
    const [bioContent, setBio] = useState('');

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    const id = getQueryParam('id') == null?0: getQueryParam('id');


    // const HandleEditorChange = (content) => {
    //     setBio(content);
    // }
    const [imageFile, setProfileImg] = useState(null);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
           // setProfileImg(URL.createObjectURL(file));

            setValue("ProfileImg", file); // Update form value
        }
    };

    const onSubmit = async (data) => {
        try {
            data.ExpertId=Number(id);
           
            if(data.ProfileImg==undefined)
            {
                // console.log(imageFile);
                navigate('/expert-third-step?id='+id);
            }
            else
            {

            
           
            const response = await expertService.addorupdateProfilePhoto(data.ExpertId,data.ProfileImg);

            if (response.status !== 200) {
                toastr.error(response.data.message);
            } else {
                if(response.data.result=='success')
                {
                    toastr.success(response.data.message);  
                    navigate('/expert-third-step?id='+id);
                }
                else{
                    toastr.error(response.data.message);  
                }
          }
        }
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error submitting form:', error);
        }
    };

    const PrevPage = async (e, mode) => {
        navigate('/'+mode+'?id='+id);
    }

    const fetchData = async () => {
        try {
            const response = await expertService.getExpertData(id);
           console.log(response);

           setProfileImg(response.profileImg==null?null:response.profileImg);

             //setValue("CreatedDate", formattedDate); 
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error fetching data:', error);
        }
    };

    
    useEffect(() => {
        fetchData();
    }, []);

 return(
    <div className="hold-transition sidebar-mini">
        <div className="wrapper">
            <HeaderComponent />
            <MenuComponent />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add/Update Expert</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/dashboard">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Add/Update Expert</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
   <div className="row">
  <div className="col-md-12">
    <div className="card card-default">
   
      <div className="card-body p-0">
        <div className="bs-stepper">
          <div className="bs-stepper-header" role="tablist">
            {/* your steps here */}
            <div className="step active" data-target="#logins-part">
              <button type="button" className="step-trigger" role="tab"  onClick={(e) => PrevPage(e,'expert-first-step')} aria-controls="logins-part" id="logins-part-trigger">
                <span className="bs-stepper-circle">1</span>
                <span className="bs-stepper-label">Basic Info</span>
              </button>
            </div>
            <div className="line" />
            <div className="step active" data-target="#information-part">
              <button type="button" className="step-trigger" role="tab"  onClick={(e) => PrevPage(e,'expert-second-step')} aria-controls="information-part" id="information-part-trigger">
                <span className="bs-stepper-circle">2</span>
                <span className="bs-stepper-label">Upload Photo</span>
              </button>
            </div>
            <div className="line" />
            <div className="step" data-target="#information-part">
              <button type="button" className="step-trigger" role="tab" onClick={(e) => PrevPage(e,'expert-third-step')} aria-controls="information-part" id="information-part-trigger">
                <span className="bs-stepper-circle">3</span>
                <span className="bs-stepper-label">Skill</span>
              </button>
            </div>
            <div className="line" />
            <div className="step" data-target="#information-part">
              <button type="button" className="step-trigger" onClick={(e) => PrevPage(e,'expert-fourth-step')} role="tab" aria-controls="information-part" id="information-part-trigger">
                <span className="bs-stepper-circle">4</span>
                <span className="bs-stepper-label">Category</span>
              </button>
            </div>
          </div>
          <div className="bs-stepper-content d-block">
            {/* your steps content here */}
            <div id="logins-part" className="content active" role="tabpanel" aria-labelledby="logins-part-trigger">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
            <div className="col-md-12">
            <div className="form-group" id="profile">
                                                                {/* <label htmlFor="profileImg">Profile Image</label> */}
                                                               
                                                               {imageFile ? (
          <div className="mt-3">
            <img
              src={imageFile}
              alt="Profile Preview"
              className="img-thumbnail"
              style={{ width: '200px', height: '200px' }}
            />
          </div>
        ) : (
          <div className="mt-3">
            <img
              src="https://apicourse.mycoursehelp.com/uploads/profile-blank.png"
              alt="Default Profile"
              className="img-thumbnail"
              style={{ width: '200px', height: '200px' }}
            />
          </div>
        )}

        <input
          type="file"
          className="form-control mt-3"
          id="imageFile"
          style={{ width: '200px' }}
          accept="image/*"
          {...register("imageFile", { required: "Profile image is required" })}
          onChange={handleImageChange}
        />
                                                                {errors.imageFile && <p className="text-danger">{errors.imageFile.message}</p>}
                                                            </div>
              </div>
              
            
          
             
              </div>
          
              <div className="pt-2">
              <button type="submit" className="btn btn-primary">Next</button>
              </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
      {/* /.card-body */}
      
    </div>
    {/* /.card */}
  </div>
</div>  </div>
                </section>
            </div>
        </div>
        <FooterComponent />
    </div>

 )
}

export default AddExpertSecondStepComponent;