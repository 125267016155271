import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Select from 'react-select';
import expertService from "../../services/expertService";

const schema = yup.object({
    ExpertId: yup.number(),
    Skilld: yup.number().required(),
    Rating: yup.number().required(),
    RatingSubject: yup.string().required(),
    RatingDescription: yup.string().required(),
}).required();

function AddUpdateReview() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });
    const query = window.location.search;
    const params = new URLSearchParams(query);

    const [skillList, setSkillList] = useState([]);
    const [expertData, setExpertData] = useState(null);
    const [defaultSkill, setDefaultSkill] = useState(null);
    const hasFetchedData = useRef(false);

    const onSubmit = async (data) => {
        try {
            data.ExpertId = Number(params.get('id'));
            const response = await expertService.saveUpdateExpert(data);
            if (response.data.result === 'success') {
                toastr.success(response.data.message);
                navigate('/expert-reviews');
            } else {
                toastr.error(response.data.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toastr.error('Something went wrong!');
        }
    };

    useEffect(() => {
        if (!hasFetchedData.current) {
            fetchInitialData();
            hasFetchedData.current = true;
        }
    }, []);

    const fetchInitialData = async () => {
        try {
            const skills = await fetchExpertSkillList();
            await fetchExpertData();
            if(params.get('revId')) {
                await fetchExpertReview(skills);
            }
        } catch (error) {
            console.error('Error fetching initial data:', error);
            toastr.error('Failed to fetch initial data');
        }
    };

    const fetchExpertData = async () => {
        try {
            const response = await expertService.getExpertData(params.get('id'));
            setExpertData(response);
        } catch (error) {
            console.error('Error fetching expert data:', error);
            toastr.error('Failed to fetch expert data');
        }
    };

    const fetchExpertSkillList = async () => {
        try {
            const response = await expertService.makeExpertSkillList(params.get('id'));
            const options = response.data.map(service => ({
                value: service.skilld,
                label: service.skillName
            }));
            setSkillList(options);
            return options; // Return skill list for further processing
        } catch (error) {
            console.error('Error fetching skill list:', error);
            toastr.error('Failed to fetch skill list');
            return []; // Return empty array in case of error
        }
    };

    const fetchExpertReview = async (skills) => {
        try {
            const response = await expertService.getReviewData(params.get('id'), params.get('revId'));
            const record = response.data;
            //console.log(record);
            setValue("Id", Number(params.get('revId')));
            setValue("Skilld", record.skilld);
            setValue("Rating", record.rating);
            setValue("RatingSubject", record.ratingSubject);
            setValue("RatingDescription", record.ratingDescription);

            // Find and set the default skill value
            const defaultSkillOption = skills.find(option => option.value === record.skilld);
            setDefaultSkill(defaultSkillOption);
        } catch (error) {
            console.error('Error fetching review data:', error);
            toastr.error('Failed to fetch review data');
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Add/Update Review</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add Review</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="ExpertId">Expert</label>
                                                    {expertData ? (
                                                        <p>{expertData.firstName} {expertData.lastName}</p>
                                                    ) : (
                                                        <p>Loading...</p>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Skilld">Choose Skill</label>
                                                    <Select
                                                        options={skillList}
                                                        value={defaultSkill}
                                                        onChange={(selectedOption) => {
                                                            setValue('Skilld', selectedOption.value);
                                                            setDefaultSkill(selectedOption);
                                                        }}
                                                    />
                                                    {errors.Skilld && <p className="text-danger">{errors.Skilld.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Rating">Rating</label>
                                                    <select
                                                        className="form-control"
                                                        id="Rating"
                                                        {...register("Rating")}
                                                    >
                                                        <option value="">Select Rating</option>
                                                        <option value="0.5">0.5</option>
                                                        <option value="1">1</option>
                                                        <option value="1.5">1.5</option>
                                                        <option value="2">2</option>
                                                        <option value="2.5">2.5</option>
                                                        <option value="3">3</option>
                                                        <option value="3.5">3.5</option>
                                                        <option value="4">4</option>
                                                        <option value="4.5">4.5</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                    {errors.Rating && <p className="text-danger">{errors.Rating.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="RatingSubject">Rating Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="RatingSubject"
                                                        placeholder="Enter Rating Title"
                                                        {...register("RatingSubject")}
                                                    />
                                                    {errors.RatingSubject && <p className="text-danger">{errors.RatingSubject.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="RatingDescription">Rating Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="RatingDescription"
                                                        placeholder="Enter Rating Description"
                                                        rows={4}
                                                        {...register("RatingDescription")}
                                                    ></textarea>
                                                    {errors.RatingDescription && <p className="text-danger">{errors.RatingDescription.message}</p>}
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                                {/* <a href="/expert-reviews" className="btn btn-secondary ml-auto float-right">Back</a> */}
                                                <a href="javascript:window.history.back();" className="btn btn-secondary ml-auto float-right">Back</a>


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default AddUpdateReview;
