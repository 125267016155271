import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import expertService from "../../services/expertService";

const schema = yup.object({
    ExpertId: yup.number(),
    SkillId: yup.number()
}).required();

function AddExpertThirdStepComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const { register, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const [expertCategories, setExpertCat] = useState([]);
    const [loading, setLoading] = useState(true);
    const [checkedSkills, setCheckedSkills] = useState([]);

    useEffect(() => {
        loadExpertCategories();
        SkillListData();
    }, []);

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    const id = getQueryParam('id') || 0;

    const loadExpertCategories = () => {
        expertService.getExpertSkillCategoryList()
            .then(response => {
                if (response && response.length > 0) {
                    setExpertCat(response);
                } else {
                    setExpertCat([]);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                toastr.error('Something Went Wrong !');
            });
    };

    const SkillListData = async () => {
        try {
            const response = await expertService.getExpertSkillListData(Number(id));
            const checkedSkills = response.map(skill => skill.skilld); // Assuming response contains skillId
            setCheckedSkills(checkedSkills);
        } catch (error) {
            toastr.error('Failed to fetch expert skills: ' + error.message);
        }
    };

    const handleSkillChange = async (e, categoryId) => {
        const isChecked = e.target.checked;
        
        // Create a new array to hold updated checkedSkills
        let updatedCheckedSkills = [...checkedSkills];
        
        if (isChecked) {
            // Add categoryId to updatedCheckedSkills if checked
            updatedCheckedSkills.push(categoryId);
        } else {
            // Remove categoryId from updatedCheckedSkills if unchecked
            updatedCheckedSkills = updatedCheckedSkills.filter(skillId => skillId !== categoryId);
        }

        // Update checkedSkills state
        setCheckedSkills(updatedCheckedSkills);

        // Perform API call to add or remove skill
        const formData = new FormData();
        formData.append('ExpertId', Number(id));
        formData.append('Skilld', categoryId);

        try {
            const response = await expertService.addorremoveExpertSkill(formData);
            if (response.data.result === 'success') {
                toastr.success(response.data.message);
            } else {
                toastr.error(response.data.message);
                // Revert UI state if API call fails
                setCheckedSkills(prevSkills => prevSkills.filter(skillId => skillId !== categoryId));
            }
        } catch (error) {
            toastr.error('Failed to update skill: ' + error.message);
            // Revert UI state if API call fails
            setCheckedSkills(prevSkills => prevSkills.filter(skillId => skillId !== categoryId));
        }
    };

    const NextPage = () => {
        navigate('/expert-fourth-step?id=' + id);
    };

    const PrevPage = (mode) => {
        navigate('/' + mode + '?id=' + id);
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Add/Update Expert</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add/Update Expert</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-default">
                                        <div className="card-body p-0">
                                            <div className="bs-stepper">
                                                <div className="bs-stepper-header" role="tablist">
                                                    <div className="step active" data-target="#logins-part">
                                                        <button type="button" className="step-trigger" onClick={() => PrevPage('expert-first-step')} role="tab" aria-controls="logins-part" id="logins-part-trigger">
                                                            <span className="bs-stepper-circle">1</span>
                                                            <span className="bs-stepper-label">Basic Info</span>
                                                        </button>
                                                    </div>
                                                    <div className="line" />
                                                    <div className="step active" data-target="#information-part">
                                                        <button type="button" className="step-trigger" onClick={() => PrevPage('expert-second-step')} role="tab" aria-controls="information-part" id="information-part-trigger">
                                                            <span className="bs-stepper-circle">2</span>
                                                            <span className="bs-stepper-label">Upload Photo</span>
                                                        </button>
                                                    </div>
                                                    <div className="line" />
                                                    <div className="step active" data-target="#information-part">
                                                        <button type="button" className="step-trigger" onClick={() => PrevPage('expert-third-step')} role="tab" aria-controls="information-part" id="information-part-trigger">
                                                            <span className="bs-stepper-circle">3</span>
                                                            <span className="bs-stepper-label">Skill</span>
                                                        </button>
                                                    </div>
                                                    <div className="line" />
                                                    <div className="step" data-target="#information-part">
                                                        <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                            <span className="bs-stepper-circle">4</span>
                                                            <span className="bs-stepper-label">Category</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="bs-stepper-content d-block">
                                                    <div id="logins-part" className="content active" role="tabpanel" aria-labelledby="logins-part-trigger">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row py-4" id="skill">
                                                                    {loading ? (
                                                                        <p>Loading...</p>
                                                                    ) : (
                                                                        expertCategories.map(category => (
                                                                            <div key={category.skilld} className="col-md-2">
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="checkbox"
                                                                                        id={`category-${category.skilld}`}
                                                                                        value={category.skilld}
                                                                                        checked={checkedSkills.includes(category.skilld)}
                                                                                        onChange={(e) => handleSkillChange(e, category.skilld)}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor={`category-${category.skilld}`}>
                                                                                        {category.skillName}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <button className="btn btn-primary" onClick={NextPage}>Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default AddExpertThirdStepComponent;
