import React, { useState, useEffect,useRef  } from "react";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import blog from "../services/prodservice";
import moment from 'moment'

function ServiceList(){
  
    const [mypost, setPostArray] = useState([]);
    const hasFetchedData = useRef(false);
  
    function DeleteService(event, elem) {
      event.preventDefault();
      if (window.confirm("Are you sure you want to delete this service?")) {
        blog.DeleteService(elem)
          .then(response => {
            // console.log(response);
            if (response.data === 1) {
              toastr.error('Service Deleted Successfully')
              loadData(1,150);
            } else {
              toastr.error('Something Went Wrong !');
            }
          })
          .catch((error) => {
            toastr.error('Something Went Wrong !');
          })
      }
    }
    
  
    
  
    useEffect(() => {
      if (!hasFetchedData.current) {
        loadData(1,300);
      hasFetchedData.current = true;
    }
    
    }, []);  
    
  
    function loadData(pageNum, pageSize){
      blog.ServiceList(pageNum,pageSize)  
      .then(response => {
           console.log(response.data);
           // setPostArray([response.data]);
           setPostArray(response.data);
           //console.log(setPostArray);
        }
      )
      .catch((error) => {
        //toastr.error('Something Went Wrong !');
      })
    }

    function makeServiceLIVE(event,id){
      event.preventDefault();
      const isChecked = event.target.checked;
      blog.serviceLIVE(id, isChecked).then(response => {
        // console.log(response);
        toastr.success(response.data.message);
        loadData(1,150);
      })
      .catch((error) => {
        toastr.error(error);
      })
    }
        return(
       <div className="hold-transition sidebar-mini">


  <div className="wrapper">
    <HeaderComponent></HeaderComponent>
  <MenuComponent></MenuComponent>
 <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Service List</h1>
        </div>
        <div className="col-sm-6">
        <a href="/add-service" className="btn btn-dark float-right"><i class="fas fa-plus" style={{fontSize: '0.8rem'}}></i> Add Service</a>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Service List</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <table id="example2" className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Created Date</th>
                    <th style={{width: '35%'}}>Service Title</th>
                    <th style={{width: '20%'}}>FAQ</th>
                    <th style={{width: '15%'}}>Inter Links</th>
                    <th style={{textAlign: 'center'}}>Action</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {mypost.map((item, index) => (
                   <tr key={index}>
                    <td>{moment(item.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                
                    <td>{item.serviceTitle}</td>
                  
                    <td align="center">
                     <div className="d-flex">
                     <a href={'/add-faq?Id='+item.servId} className="btn btn-sm btn-warning">Add FAQ</a>
                      <a href={'/faq-list?Id='+item.servId} className="btn btn-sm btn-success ml-2">FAQ's</a>
                     </div>
                      </td>
                      <td>
                      <a href={'/add-footer-link?Id='+item.servId} className="btn btn-sm btn-primary">Add Links</a>
                      </td>
                    <td align="center">
                        <a href={"/edit-service?id="+item.servId}><i className="far fa-edit"></i></a>
                        {/* <a style={{ marginLeft: '10px' }} href={"/delete-blog?id=" + item.blogId}><i className="fas fa-trash-alt"></i></a> */}
                        <a
  style={{ marginLeft: '10px' }}
  href="javascript:void(0)"
  onClick={(event) => DeleteService(event, item.servId)}
>
  <i className="fas fa-trash-alt"></i>
</a> 

                    </td>
                   
                    <td style={{marginTop: '50px'}}>
                  
         {/* <a href="javascript:;" className="ml-2" onClick={(event) => makeServiceLIVE(event, item.servId)}><i className="fas fa-check"></i></a> */}
      
<label className="switch">
  <input type="checkbox" checked={item.isActive}  onChange={(event) => makeServiceLIVE(event, item.servId)}/>
  <span className="slider" />
</label>


                    </td>
                  </tr>
               ))}
                 
                </tbody>
              
              </table>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>
</div>
  <FooterComponent></FooterComponent>
</div>

)
}

export default ServiceList;