import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import moment from 'moment';
import expertService from "../../services/expertService";
import Select from 'react-select';

function ExpertReviewList() {
    const location = useLocation();
    const [expertReviews, setExpertReviews] = useState([]);
    const hasFetchedData = useRef(false);
    const [loading, setLoading] = useState(true);
    const [expertList, setExpertList] = useState([]);
    const [selectedExpertId, setSelectedExpertId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    const id = getQueryParam('id');

    useEffect(() => {
        if (!hasFetchedData.current) {
            fetchExpertRevList();
            hasFetchedData.current = true;
        }
    }, []);

    useEffect(() => {
        loadReviews(currentPage);
    }, [selectedExpertId, currentPage]);

    const loadReviews = (page) => {
        setLoading(true);
        expertService.makeExpertReviews(page, 20, (selectedExpertId == null ? 0 : selectedExpertId))
            .then(response => {
                if (response && response.data.length > 0) {
                    setExpertReviews(response.data);
                    setTotalPages(response.totalPages); // Assuming the API response contains the total pages
                } else {
                    setExpertReviews([]);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                toastr.error('Something Went Wrong !');
            });
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const DeleteExpertReview = (event, id) => {
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete this expert review?")) {
            expertService.makeExpertReviewDelete(id)
                .then(response => {
                    if (response.data.result === 'success') {
                        toastr.success(response.data.message);
                        loadReviews(currentPage);
                    } else {
                        toastr.error(response.data.message);
                    }
                })
                .catch(() => {
                    toastr.error('Something Went Wrong !');
                });
        }
    };

    const fetchExpertRevList = async () => {
        try {
            const response = await expertService.getAllExperts();
            const options = response.data.map(service => ({
                value: service.expertId,
                label: `${service.firstName} ${service.lastName} (${truncateText(service.email, 13)})`
            }));
            setExpertList(options);
        } catch (error) {
            console.error('Error fetching expert list:', error);
            toastr.error('Failed to fetch expert list');
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-9">
                                    <h1>Expert Reviews</h1>
                                </div>
                                <div className="col-sm-3">
                                            
                                   
                                    <Select
                                        options={expertList}
                                        onChange={(selectedOption) => {
                                            setSelectedExpertId(selectedOption ? selectedOption.value : null);
                                            setCurrentPage(1); // Reset to the first page when expert is changed
                                        }}
                                    />
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Created Date</th>
                                                        <th>Expert Name</th>
                                                        <th>Skill</th>
                                                        <th>Rating</th>
                                                        <th>Rating Title</th>
                                                        <th>Rating Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {expertReviews.map((expert, index) => (
                                                        <tr key={index}>
                                                            <td>{moment(expert.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                            <td className="expert-name-cell">
                    {expert.firstName} {expert.lastName}
                    <a
                        className="btn btn-sm btn-primary add-review-btn"
                        href={`/add-update-review?id=${expert.expertId}`}
                    >
                        Add Review
                    </a>
                </td>
                                                            <td>{expert.skillName}</td>
                                                            <td>{expert.rating}</td>
                                                            <td title={expert.ratingSubject}>
                                                                {truncateText(expert.ratingSubject, 15)}
                                                            </td>
                                                            <td title={expert.ratingDescription}>
                                                                {truncateText(expert.ratingDescription, 15)}
                                                            </td>
                                                            <td>
                                                                <a href={`/add-update-review?id=${expert.expertId}&revId=${expert.id}`}>
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                                <a
                                                                    style={{ marginLeft: '10px' }}
                                                                    href="javascript:void(0)"
                                                                    onClick={(event) => DeleteExpertReview(event, expert.id)}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="pagination pt-4 d-none">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                    disabled={currentPage === 1}
                                                >
                                                    Previous
                                                </button>
                                                <span style={{marginLeft:'10px',marginRight:'10px'}} className="page-number">{currentPage} of {totalPages}</span>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterComponent />
            </div>
        </div>
    );

    
}

export default ExpertReviewList;
