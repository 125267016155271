import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as yup from "yup";
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
import blogService from "../services/blogService";
import 'toastr/build/toastr.min.css';

// Register languages with highlight.js
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', xml);

const schema = yup.object({
    BlogName: yup.string().required(),
    VirtualUrl: yup.string().required(),
    MetaTitle: yup.string(),
    MetaDescription: yup.string().required(),
    MetaKeyword: yup.string(),
    CategoryId: yup.number().required(),
}).required();

const modules = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['code-block'], // Added code-block option
        ['clean']
    ],
    syntax: {
        highlight: text => hljs.highlightAuto(text).value,
    },
};

const formats = [
    'font', 'header', 'size', 'bold', 'italic', 'underline', 'strike',
    'color', 'background', 'script', 'list', 'bullet', 'indent',
    'direction', 'align', 'link', 'image', 'video', 'code-block'
];

function AddBlog() {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [imageId, setImageId] = useState(null);
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });
    const [blogContent, setBlogDescription] = useState('');

    const HandleEditorChange = (content) => {
        setBlogDescription(content);
    }

    const selectedBlog = watch("BlogName");

    const fetchCategories = async () => {
        try {
            const response = await blogService.getBlogCategoryList();
            setCategories(response);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toastr.error('Failed to fetch categories');
        }
    };

    useEffect(() => {
        fetchCategories();
        if (selectedBlog) {
            const virtualUrl = selectedBlog
                .replace(/[^a-z0-9\s]/gi, '')
                .replace(/^\s+/g, '')
                .replace(/\s+$/g, '')
                .replace(/[_\s]/g, '-')
                .toLowerCase()
                .substring(0, 140);
            setValue("VirtualUrl", virtualUrl);
        }
    }, [selectedBlog, setValue]);

    const onSubmit = async (data) => {
        try {
            data.ImageId = imageId;
            data.BlogDescription = blogContent;
            const response = await blogService.addBlog(data);

            if (response.status === 409) {
                toastr.error(response.data.message);
            } else {
                toastr.success('Blog added successfully');
                navigate('/blog-list');
            }
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error submitting form:', error);
        }
    };

    const handleImageUpload = async (e) => {
        try {
            const formData = new FormData();
            const file = e.target.files[0];
            formData.append("file", file);

            const response = await blogService.uploadImage(formData);

            if (response.status === 200) {
                setImageId(response.data.imageId);
                toastr.success('Image uploaded successfully');
            } else {
                toastr.error('Failed to upload image');
            }
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error uploading image:', error);
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Add Blog</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add Blog</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="CategoryId">Category</label>
                                                    <select
                                                        className="form-control"
                                                        id="CategoryId"
                                                        {...register("CategoryId")}
                                                    >
                                                        <option value="">Select Category</option>
                                                        {categories.map(category => (
                                                            <option key={category.categoryId} value={category.categoryId}>{category.categoryName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.CategoryId && <p className="text-danger">{errors.CategoryId.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="BlogName">Blog Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="BlogName"
                                                        placeholder="Enter Blog Name"
                                                        {...register("BlogName")}
                                                    />
                                                    {errors.BlogName && <p className="text-danger">{errors.BlogName.message}</p>}
                                                </div>
                                                <div className="form-group" style={{ height: '300px' }}>
                                                    <label htmlFor="blogContent">Service Content</label>
                                                    <ReactQuill
                                                        value={blogContent}
                                                        onChange={HandleEditorChange}
                                                        modules={modules}
                                                        formats={formats}
                                                        className="important-height"
                                                    />
                                                </div>
                                                <div className="form-group mt-5 pt-5">
                                                    <label htmlFor="VirtualUrl">Virtual URL</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="VirtualUrl"
                                                        placeholder="Enter Virtual URL"
                                                        {...register("VirtualUrl")}
                                                    />
                                                    {errors.VirtualUrl && <p className="text-danger">{errors.VirtualUrl.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaTitle">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaTitle"
                                                        placeholder="Enter Meta Title"
                                                        {...register("MetaTitle")}
                                                    />
                                                    {errors.MetaTitle && <p className="text-danger">{errors.MetaTitle.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaDescription">Meta Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="MetaDescription"
                                                        placeholder="Enter Meta Description"
                                                        rows={3}
                                                        {...register("MetaDescription")}
                                                    ></textarea>
                                                    {errors.MetaDescription && <p className="text-danger">{errors.MetaDescription.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaKeyword">Meta Keywords</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaKeyword"
                                                        placeholder="Enter Meta Keywords"
                                                        {...register("MetaKeyword")}
                                                    />
                                                    {errors.MetaKeyword && <p className="text-danger">{errors.MetaKeyword.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Image">Upload Image</label>
                                                    <input
                                                        type="file"
                                                        className="form-control-file"
                                                        id="Image"
                                                        accept="image/*"
                                                        onChange={handleImageUpload}
                                                    />
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Save As Draft
                                                </button>
                                                <a href="/blog-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default AddBlog;
