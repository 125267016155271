import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import faq from "../services/faqService";

const schema = yup.object({
    FaqQuestion: yup.string().required(),
    FaqAnswer: yup.string().required()
}).required();

function EditFAQ() {
    const location = useLocation();
    const navigate = useNavigate();
    const [faqData, setFaqData] = useState(null);

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    const id = getQueryParam('Id');

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (id) {
            // Fetch existing FAQ data if id is present
            faq.FAQData(id).then(response => {
                if (response.data) {
                    setFaqData(response.data);
                    setValue("FaqQuestion", response.data.faqQuestion);
                    setValue("FaqAnswer", response.data.faqAnswer);
                }
            }).catch(() => {
                toastr.error('Failed to fetch FAQ data');
            });
        }
    }, [id, setValue]);

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append("FaqQuestion", data.FaqQuestion);
            formData.append("FaqAnswer", data.FaqAnswer);
            if (id) {
                formData.append("id", id);
            }

            const response = await faq.addUpdateFAQ(formData);

            if (response === -1) {
                toastr.error('FAQ exists !!');
            } else {
                toastr.success('FAQ updated successfully');
                //navigate('/faq-list?Id='+id); // Navigate back to FAQ list
            }
        } catch (error) {
            toastr.error('Something Went Wrong !');
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Edit FAQ</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Edit FAQ</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="FaqQuestion">Question</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="FaqQuestion"
                                                        placeholder="Enter FAQ Question"
                                                        name="FaqQuestion"
                                                        {...register("FaqQuestion")}
                                                    />
                                                    {errors.FaqQuestion && <p className="text-danger">{errors.FaqQuestion.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="FaqAnswer">Answer</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="FaqAnswer"
                                                        placeholder="Enter FAQ Answer"
                                                        rows={5}
                                                        name="FaqAnswer"
                                                        {...register("FaqAnswer")}
                                                    ></textarea>
                                                    {errors.FaqAnswer && <p className="text-danger">{errors.FaqAnswer.message}</p>}
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                                <a href="#" onClick={() => window.history.back()} className="btn btn-secondary ml-auto float-right">Back</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default EditFAQ;
