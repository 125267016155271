import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import faq from "../services/faqService";
import moment from 'moment';

function FAQList() {
    const [mypost, setPostArray] = useState([]);

    const location = useLocation();

    // Function to get query parameter by name
    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    function DeleteFAQ(event, elem) {
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete this FAQ?")) {
            faq.DeleteFAQ(elem)
                .then(response => {
                    if (response.data === 1) {
                        toastr.error('FAQ Deleted Successfully');
                        const id = getQueryParam('Id');
                        loadData(id);
                    } else {
                        toastr.error('Something Went Wrong !');
                    }
                })
                .catch(() => {
                    toastr.error('Something Went Wrong !');
                });
        }
    }

    useEffect(() => {
        const id = getQueryParam('Id');
        loadData(id);
    }, []);

    function loadData(Id) {
        faq.FAQList(Id)
            .then(response => {
                if (Array.isArray(response.data)) {
                    setPostArray(response.data);
                } else {
                    console.error('Expected array but received:', response.data);
                    setPostArray([]);
                }
            })
            .catch(() => {
                toastr.error('Something Went Wrong !');
            });
    }

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>FAQ List</h1>
                                </div>
                                <div className="col-sm-6">
                             
                                    <a href={`/add-faq?Id=${getQueryParam('Id')}`} className="btn btn-success float-right">
                                        <i className="fas fa-plus" style={{ fontSize: '0.8rem' }}></i> Add FAQ
                                    </a>
                                    <a href={`/service-list`} style={{ marginRight: '10px'}} className="btn btn-warning float-right">
                                        Back
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                    
                                        <div className="card-body">
                                            <table id="example2" className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Created Date</th>
                                                        <th style={{ width: '70%' }}>Question</th>
                                                        <th style={{ textAlign: 'center' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mypost.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{moment(item.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                            <td>{item.faqQuestion}</td>
                                                            <td align="center">
                                                                <a href={"/edit-faq?Id=" + item.id}>
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                                <a
                                                                    style={{ marginLeft: '10px' }}
                                                                    href="javascript:void(0)"
                                                                    onClick={(event) => DeleteFAQ(event, item.id)}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterComponent />
            </div>
        </div>
    );
}

export default FAQList;
