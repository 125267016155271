import axios from "axios";

const authHeaders = {
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  };

var authOptions = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  },
};

const config = {
    headers: {
      ...authHeaders,
      "Content-Type": "multipart/form-data", // Set the content type for FormData
    },
  };
  

class ExpertService {
  async editExpertCategory(Id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetHelpCat/${Id}`,
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to edit expert help category: " + error.message);
    }
  }
  async addorupdateExpertCategory(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Expert/SaveHelpWithCat",
        formData,
        authOptions
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error(
        "Failed to add or update expert help category: " + error.message
      );
    }
  }

  async getExpertCategoryList() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "Expert/GetHelpCatList",
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error(
        "Failed to load expert help category list: " + error.message
      );
    }
  }

  async deleteExpertCategory(id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/DeleteHelpCat?id=${id}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error(
        "Failed to delete expert help category: " + error.message
      );
    }
  }

  //Skill

  async editExpertSkillCategory(Id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetSkillCat/${Id}`,
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to edit expert skill category: " + error.message);
    }
  }

  async getExpertData(Id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetExpert?id=${Id}`,
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to get expert data: " + error.message);
    }
  }

  async getExpertSkillListData(Id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetExpertSkillListById?id=${Id}`,
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to get expert data: " + error.message);
    }
  }

  async getExpertHelpCatListData(Id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetExpertHelpCatListById?id=${Id}`,
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to get expert data: " + error.message);
    }
  }

  

  async addorupdateExpertSkillCategory(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Expert/SaveSkillWithCat",
        formData,
        authOptions
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error(
        "Failed to add or update expert skill category: " + error.message
      );
    }
  }

  async addorupdateProfilePhoto(id, imageFile) {
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("imageFile", imageFile);

      console.log(id);
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Expert/UploadImage",
        formData,
        config
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error(
        "Failed to add or update expert profile photo: " + error.message
      );
    }
  }

  async getExpertSkillCategoryList() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "Expert/GetSkillCatList",
        authOptions
      );
      return response.data;
    } catch (error) {
      throw new Error(
        "Failed to load expert skill category list: " + error.message
      );
    }
  }

  async deleteExpertSkillCategory(id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/DeleteSkillCat?id=${id}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error(
        "Failed to delete expert skill category: " + error.message
      );
    }
  }

  async getAllExperts() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetExperts`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error("Failed to get experts: " + error.message);
    }
  }

  async makeExpertPublish(id, status) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/MakeItPublish?id=${id}&isActive=${status}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error(
        "Failed to delete expert skill category: " + error.message
      );
    }
  }

  async makeExpertRemove(id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/ExpertRemove?id=${id}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error(
        "Failed to delete expert : " + error.message
      );
    }
  }

  async makeExpertSkillList(id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetExpertBySkillList?id=${id}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error(
        "Failed to delete expert : " + error.message
      );
    }
  }

  async addorupdateExpertFirstStep(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Expert/SaveOrUpdateExpert",
        formData,
        authOptions
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error("Failed to add or update expert: " + error.message);
    }
  }

  async addorremoveExpertSkill(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Expert/AddOrRemoveExpertSkill",
        formData,
        authOptions
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error("Failed to add or update expert: " + error.message);
    }
  }

  async addorremoveExpertCategory(formData) {
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Expert/AddOrRemoveExpertHelpCategory",
        formData,
        authOptions
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error("Failed to add or update expert: " + error.message);
    }
  }

  async saveUpdateExpert(formData){
    try {
      // Make the API call with the formData
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "Expert/SavUpdateExpertReviewList",
        formData,
        authOptions
      );

      return response; // Return the response data
    } catch (error) {
      // Handle errors
      throw new Error("Failed to add or update expert: " + error.message);
    }
  }

  async makeExpertReviews(pagenum, pagesize, id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetReviews?pageNumber=${pagenum}&pageSize=${pagesize}&id=${id}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error(
        "Failed to delete expert : " + error.message
      );
    }
  }

  async makeExpertReviewDelete(id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/DeleteExpertRev?id=${id}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error(
        "Failed to delete expert review: " + error.message
      );
    }
  }

  async getReviewData(expertId, id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}Expert/GetReviewData?expertId=${expertId}&revId=${id}`,
        authOptions
      );
      return response;
    } catch (error) {
      throw new Error(
        "Failed to delete expert review: " + error.message
      );
    }
  }

}

export default new ExpertService();
