import React, {useEffect,useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import { Editor } from '@tinymce/tinymce-react';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import expertService from "../../services/expertService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';

// Register languages with highlight.js
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', xml);


const schema = yup.object({
    ExpertId: yup.number(),
    FirstName: yup.string().required(),
    LastName: yup.string().required(),
    Bio: yup.string(),
    Email: yup.string().required(),
    PhoneNumber: yup.string().required(),
    CreatedDate: yup.string(),
    LastCompletedOrderDate: yup.string(),
    CompleteOrders: yup.number().required()
}).required();

const modules = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['code-block'], // Added code-block option
        ['clean']
    ],
    syntax: {
        highlight: text => hljs.highlightAuto(text).value,
    },
};

const formats = [
    'font', 'header', 'size', 'bold', 'italic', 'underline', 'strike',
    'color', 'background', 'script', 'list', 'bullet', 'indent',
    'direction', 'align', 'link', 'image', 'video', 'code-block'
];



function AddExpertFirstStepComponent(){

    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });
    const [startDate, setStartDate] = useState(null);
    const [lastCompletedOrderDate, setLastCompletedOrderDate] = useState(null);
    const [bioContent, setBio] = useState('');

  

    const HandleEditorChange = (content) => {
        setBio(content);
    }

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    const id = getQueryParam('id') == null?0: getQueryParam('id');


    const onSubmit = async (data) => {
        try {
            data.ExpertId=id;
            data.Bio=bioContent;
            if(startDate!=undefined)
            {
                data.CreatedDate = startDate;
            }
            else{
                data.CreatedDate = null;
            }

            if(lastCompletedOrderDate!=undefined)
            {
                data.LastCompletedOrderDate = lastCompletedOrderDate;
            }
            else{
                data.LastCompletedOrderDate = null;
            }

            
           
          
            console.log(data);
            const response = await expertService.addorupdateExpertFirstStep(data);

            if (response.status === 409) {
                toastr.error(response.data.message);
            } else {
                if(response.data.result==0)
                {
                    toastr.error(response.data.message);
                }
                else{                
                if(id>0)
                {
                    toastr.success(response.data.message);
                    
                }
                else{
                    toastr.success(response.data.message);                   
                }

                navigate('/expert-second-step?id='+response.data.result);
            }               
          }
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error submitting form:', error);
        }
    };

    const PrevPage = async (e, mode) => {
        if(id!=0)
        {
        navigate('/'+mode+'?id='+id);
        }
    }

    const fetchData = async () => {
        try {
            const response = await expertService.getExpertData(id);
           console.log(response);

           const createdDate = new Date(response.createdDate);
           const formattedDate = createdDate.toISOString().split('T')[0];

           const lastOrderDate = new Date(response.lastCompletedOrderDate);
           const formattedOrderDate = lastOrderDate.toISOString().split('T')[0];
            
             setValue("CreatedDate", formattedDate); 
             setValue("FirstName", response.firstName);
             setValue("LastName", response.lastName);
             setValue("Email", response.email);
             setValue("PhoneNumber", response.phoneNumber);
             setValue("CompleteOrders", response.completeOrders);
             setValue("ExpertId", Number(response.expertId));
             setValue("LastCompletedOrderDate", formattedOrderDate); 
            // setValue("Bio", response.bio); 
             setBio(response.bio);
             document.getElementById('Email').readOnly = true;
            // setValue("HelpCatName", data.helpCatName);
            // setValue("VirtualUrl", data.virtualUrl);
        } catch (error) {
           // toastr.error('Something Went Wrong!');
            console.error('Error fetching data:', error);
        }
    };

    
    useEffect(() => {
        fetchData();
    }, []);


 return(
    
    <div className="hold-transition sidebar-mini">
        <div className="wrapper">
            <HeaderComponent />
            <MenuComponent />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add/Update Expert</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/dashboard">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Add/Update Expert</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
   <div className="row">
  <div className="col-md-12">
    <div className="card card-default">
   
      <div className="card-body p-0">
        <div className="bs-stepper">
          <div className="bs-stepper-header" role="tablist">
            {/* your steps here */}
            <div className="step active" data-target="#logins-part">
              <button type="button" className="step-trigger" role="tab" onClick={(e) => PrevPage(e,'expert-first-step')} aria-controls="logins-part" id="logins-part-trigger">
                <span className="bs-stepper-circle">1</span>
                <span className="bs-stepper-label">Basic Info</span>
              </button>
            </div>
            <div className="line" />
            <div className="step" data-target="#information-part">
              <button type="button" className="step-trigger" role="tab" onClick={(e) => PrevPage(e,'expert-second-step')} aria-controls="information-part" id="information-part-trigger">
                <span className="bs-stepper-circle">2</span>
                <span className="bs-stepper-label">Upload Photo</span>
              </button>
            </div>
            <div className="line" />
            <div className="step" data-target="#information-part">
              <button type="button" className="step-trigger" role="tab" onClick={(e) => PrevPage(e,'expert-third-step')} aria-controls="information-part" id="information-part-trigger">
                <span className="bs-stepper-circle">3</span>
                <span className="bs-stepper-label">Skill</span>
              </button>
            </div>
            <div className="line" />
            <div className="step" data-target="#information-part">
              <button type="button" className="step-trigger" role="tab" onClick={(e) => PrevPage(e,'expert-fourth-step')} aria-controls="information-part" id="information-part-trigger">
                <span className="bs-stepper-circle">4</span>
                <span className="bs-stepper-label">Category</span>
              </button>
            </div>
          </div>
          <div className="bs-stepper-content d-block">
            {/* your steps content here */}
            <div id="logins-part" className="content active" role="tabpanel" aria-labelledby="logins-part-trigger">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Created Date (Optional)</label>
                <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            id="CreatedDate"
                                                                            {...register("CreatedDate")}
                                                                           onChange={(e) => setStartDate(e.target.value)}
                                                                        />
                {/* <input type="date" className="form-control" id="CreatedDate" {...register("CreatedDate")} placeholder="Choose created date" /> */}
                {/* <DatePicker selected={startDate} className="form-control" onChange={(date) => setStartDate(date)} /> */}
              </div>
              </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">First Name</label>
                <input type="text" className="form-control" id="FirstName" {...register("FirstName")}  placeholder="Enter first name" />
                {errors.FirstName && <p className="text-danger">{errors.FirstName.message}</p>}
              </div>
              </div>
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Last Name</label>
                <input type="text" className="form-control" id="LastName" {...register("LastName")}  placeholder="Enter last name" />
                {errors.LastName && <p className="text-danger">{errors.LastName.message}</p>}
              </div>
              </div>
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Email Id</label>
                <input type="email" className="form-control" id="Email" {...register("Email")}  placeholder="Enter email id" />
                {errors.Email && <p className="text-danger">{errors.Email.message}</p>}
              </div>
              </div>
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Phone Number</label>
                <input type="text" className="form-control" id="PhoneNumber" {...register("PhoneNumber")}  placeholder="Enter phone number" />
                {errors.PhoneNumber && <p className="text-danger">{errors.PhoneNumber.message}</p>}
              </div>
              </div>
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">No. of Completed Orders</label>
                <input type="number" className="form-control" id="CompleteOrders" {...register("CompleteOrders")}  placeholder="Enter completed orders" />
                {errors.CompleteOrders && <p className="text-danger">{errors.CompleteOrders.message}</p>}
              </div>
              </div>
              <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Last Completed Order Date (Optional)</label>
                {/* <input type="date" className="form-control" id="LastCompletedOrderDate" {...register("LastCompletedOrderDate")}  placeholder="Choose last completed order date" /> */}
                <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            id="LastCompletedOrderDate"
                                                                            {...register("LastCompletedOrderDate")}
                                                                            onChange={(e) => setLastCompletedOrderDate(e.target.value)}
                                                                        />
              </div>
              </div>
              <div className="col-md-12 mb-5">
              <div className="form-group" style={{ height: '200px' }}>
                                                    <label htmlFor="Bio">Bio</label>
                                                    <ReactQuill
                                                        value={bioContent}
                                                        onChange={HandleEditorChange}
                                                        modules={modules}
                                                        formats={formats}
                                                        className="important-height"
                                                    />
                                                </div>
              </div>
             
              </div>
              <div className="pt-5"  style={{ marginTop: '90px' }}>
              <button className="btn btn-primary">Next</button>
              </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
      {/* /.card-body */}
      
    </div>
    {/* /.card */}
  </div>
</div>  </div>
                </section>
            </div>
        </div>
        <FooterComponent />
    </div>

 )
}

export default AddExpertFirstStepComponent;