import React, {useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../_partials/header.component";
import FooterComponent from "../../_partials/footer.component";
import MenuComponent from "../../_partials/menu.component";
import toastr from 'toastr';
import { Editor } from '@tinymce/tinymce-react';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import expertService from "../../services/expertService";

const schema = yup.object({
    HelpCatName: yup.string().required(),
    VirtualUrl: yup.string().required()
}).required();

function AddHelpCategory(){
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });
    const helpCat = watch("HelpCatName");
    useEffect(() => {
        if (helpCat) {
            const virtualUrl = helpCat
                .replace(/[^a-z0-9\s]/gi, '')
                .replace(/^\s+/g, '')
                .replace(/\s+$/g, '')
                .replace(/[_\s]/g, '-')
                .toLowerCase()
                .substring(0, 140);
            setValue("VirtualUrl", virtualUrl);
        }
    }, [helpCat, setValue]);

    const onSubmit = async (data) => {
        try {
            const response = await expertService.addorupdateExpertCategory(data);

            if (response.status !== 200) {
                toastr.error(response.data.message);
            } else {
                toastr.success(response.data.message);
                navigate('/expert-category-list'); // Navigate to blog category list
            }
        } catch (error) {
            toastr.error('Something Went Wrong!');
            console.error('Error submitting form:', error);
        }
    };


    return(
        <div className="hold-transition sidebar-mini">
        <div className="wrapper">
            <HeaderComponent />
            <MenuComponent />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add Expert Category</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/dashboard">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Add Expert Category</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="HelpCatName">Expert Category</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="HelpCatName"
                                                    placeholder="Enter Expert Category Name"
                                                    {...register("HelpCatName")}
                                                />
                                                {errors.HelpCatName && <p className="text-danger">{errors.HelpCatName.message}</p>}
                                            </div>
                                          
                                            <div className="form-group d-none">
                                                <label htmlFor="VirtualUrl">Virtual URL</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="VirtualUrl"
                                                    placeholder="Enter Virtual URL"
                                                    {...register("VirtualUrl")}
                                                />
                                                {errors.VirtualUrl && <p className="text-danger">{errors.VirtualUrl.message}</p>}
                                            </div>
                                         
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                            <a href="/expert-category-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterComponent />
    </div>
    );
}

export default AddHelpCategory;